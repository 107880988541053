<template>
    <div class="history">
        <div class="title">
            <div class="btns">
                <span @click="orderTable=false" :class="{'active-span':!orderTable}" class="activeorders"> سفارشات فعال </span>
                <span @click="orderTable=true" :class="{'active-span':orderTable}" class="tradehistory"> تاریخچه سفارشات </span>
                <div :class="orderTable ? 'ot' : 'ao'" class="underline"></div>
            </div>
        </div>
        <div class="table">
            <table>
                <tr class="thead">
                    <td class="remove" v-if="orderTable"> زمان </td>
                    <td> معامله </td>
                    <td> مقدار </td>
                    <td class="remove"> مجموع ({{$coinLabel['TOMAN']}}) </td>
                    <td> قیمت ({{$coinLabel['TOMAN']}}) </td>
                    <td class="remove" v-if="orderTable"> انجام شده </td>
                    <td> {{orderTable ? 'وضعیت':'عملیات'}} </td>
                </tr>
                <tr class="tbody" v-for="(item,index) in filterTable" :key="index">
                    <td class="remove" v-if="orderTable"> {{$G2J(item.createdAtDateTime)}} </td>
                    <td v-if="!orderTable"> {{ item.orderType.includes('BUY')? 'خرید':'فروش' }} </td>
                    <td v-if="orderTable"> {{ $orderType[item.orderType] }} </td>
                    <td> {{ $toLocal(item.amount,$decimal[$route.params.tradeFrom]) }} </td>
                    <td class="remove"> {{ $toLocal(item.wholePrice,$decimal['TOMAN']) }} </td>
                    <td> {{ $toLocal(item.unitPrice,$decimal['TOMAN']) }} </td>
                    <td class="remove" v-if="orderTable"> {{ item.executedPercent }}%</td>
                    <td class="success" v-if="orderTable && item.orderStatus.includes('FINISHED')"> موفق </td>
                    <td class="fail" v-if="orderTable && item.orderStatus.includes('CANCELED')"> لغو شده </td>
                    <td class="td-btn" v-if="!orderTable"> لغو سفارش </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name:'ReUsableHistory',
        data() {
            return {
                orderTable:false,
                activeOrders:[
                    {
                        amount: 0.032,
                        createdAtDateTime: "2022-05-09T10:23:16",
                        destinationCoin: "'TOMAN'",
                        executedAmount: 0,
                        executedPercent: 0,
                        orderType: "LIMITED_SELL",
                        orderStatus:'IS_OPEN',
                        overallValue: 64000,
                        sourceCoin: "BITCOIN",
                        unitPrice: 2000000,
                        updatedAtDateTime: "2022-05-09T10:23:16",
                        wholePrice: 64000
                    },
                    {
                        amount: 0.032,
                        createdAtDateTime: "2022-05-09T10:23:16",
                        destinationCoin: "BITCOIN",
                        executedAmount: 0,
                        executedPercent: 0,
                        orderType: "LIMITED_BUY",
                        orderStatus:'IS_OPEN',
                        overallValue: 64000,
                        sourceCoin: "'TOMAN'",
                        unitPrice: 2000000,
                        updatedAtDateTime: "2022-05-09T10:23:16",
                        wholePrice: 64000
                    },
                    {
                        amount: 0.032,
                        createdAtDateTime: "2022-05-09T10:23:16",
                        destinationCoin: "BITCOIN",
                        executedAmount: 0,
                        executedPercent: 0,
                        orderType: "LIMITED_BUY",
                        orderStatus:'IS_OPEN',
                        overallValue: 64000,
                        sourceCoin: "'TOMAN'",
                        unitPrice: 2000000,
                        updatedAtDateTime: "2022-05-09T10:23:16",
                        wholePrice: 64000
                    },
                    {
                        amount: 0.032,
                        createdAtDateTime: "2022-05-09T10:23:16",
                        destinationCoin: "BITCOIN",
                        executedAmount: 0,
                        executedPercent: 0,
                        orderType: "LIMITED_BUY",
                        orderStatus:'IS_OPEN',
                        overallValue: 64000,
                        sourceCoin: "'TOMAN'",
                        unitPrice: 2000000,
                        updatedAtDateTime: "2022-05-09T10:23:16",
                        wholePrice: 64000
                    },
                    {
                        amount: 0.032,
                        createdAtDateTime: "2022-05-09T10:23:16",
                        destinationCoin: "BITCOIN",
                        executedAmount: 0,
                        executedPercent: 0,
                        orderType: "LIMITED_BUY",
                        overallValue: 64000,
                        orderStatus:'IS_OPEN',
                        sourceCoin: "'TOMAN'",
                        unitPrice: 2000000,
                        updatedAtDateTime: "2022-05-09T10:23:16",
                        wholePrice: 64000
                    },
                ],
                tradeHistory:[
                    {
                        amount: 0.032,
                        createdAtDateTime: "2022-05-09T10:23:16",
                        destinationCoin: "'TOMAN'",
                        executedAmount: 0,
                        executedPercent: 0,
                        orderType: "LIMITED_SELL",
                        overallValue: 64000,
                        sourceCoin: "BITCOIN",
                        orderStatus:'FINISHED',
                        unitPrice: 2000000,
                        updatedAtDateTime: "2022-05-09T10:23:16",
                        wholePrice: 64000
                    },
                    {
                        amount: 0.032,
                        createdAtDateTime: "2022-05-09T10:23:16",
                        destinationCoin: "BITCOIN",
                        executedAmount: 0,
                        executedPercent: 0,
                        orderType: "LIMITED_BUY",
                        orderStatus:'FINISHED',
                        overallValue: 64000,
                        sourceCoin: "'TOMAN'",
                        unitPrice: 2000000,
                        updatedAtDateTime: "2022-05-09T10:23:16",
                        wholePrice: 64000
                    },
                    {
                        amount: 0.032,
                        createdAtDateTime: "2022-05-09T10:23:16",
                        destinationCoin: "BITCOIN",
                        executedAmount: 0,
                        executedPercent: 0,
                        orderType: "LIMITED_BUY",
                        orderStatus:'CANCELED',
                        overallValue: 64000,
                        sourceCoin: "'TOMAN'",
                        unitPrice: 2000000,
                        updatedAtDateTime: "2022-05-09T10:23:16",
                        wholePrice: 64000
                    },
                ]
            }
        },
        computed:{
            filterTable(){
                let data
                if(this.orderTable){
                    data=this.tradeHistory
                }else{
                    data=this.activeOrders
                }
                return data
            }
        }
    }
</script>

<style lang="scss" scoped>
.thead{
    font-size: clamp(14px,1vw,16px);
    font-weight: 400;
    color: var(--grey);
}
.tbody{
    font-size: clamp(12px,1vw,14px);
    font-weight: 400;
}
.td-btn{
    opacity:.5;
    cursor: pointer;
    &:hover{
        opacity: 1;
        color: var(--red);
    }
}
.table{
    height: 100%;
    width: 100%;
    table{
        border-collapse: collapse;
        width: 100%;
        text-align: center;
        height: 100%;
    }
    tr{
        min-height: 37px;
        border-bottom: 1px solid var(--light-grey);
    }
}
.ot{
    left: 15px;
}
.ao{
    right: 5px;
}
.underline{
    width: 60px;
    height: 3px;
    border-radius: 4px;
    background: var(--primary-color);
    position: absolute;
    bottom: 0;
}
.history{
    background: var(--slow-yellow);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    .title{
        width: 100%;
        height: 10%;
        border-radius: 4px 4px 0px 0px;
        padding: 5px 12px;
        .btns{
            display: flex;
            flex-direction: row;
            column-gap: 24px;
            position: relative;
            justify-content: space-between;
            width: 20%;
            span{
                color: var(--grey);
                font-size: clamp(14px,1vw,16px);
                font-weight: 400;
            }
        }
    }

}
@media only screen and(max-width:1200px){
    .btns{
        width: 30% !important;
    }
}
@media only screen and(max-width:700px){
    .btns{
        width: 60% !important;
    }
    .remove{
        display: none;
    }
}
@media only screen and(max-width:500px){
    .btns{
        width: 80% !important;
    }
}
@media only screen and(max-width:400px){
    .btns{
        width: 100% !important;
    }
}
</style>