import { render, staticRenderFns } from "./ReUsableHistory.vue?vue&type=template&id=19ff112b&scoped=true&"
import script from "./ReUsableHistory.vue?vue&type=script&lang=js&"
export * from "./ReUsableHistory.vue?vue&type=script&lang=js&"
import style0 from "./ReUsableHistory.vue?vue&type=style&index=0&id=19ff112b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19ff112b",
  null
  
)

export default component.exports